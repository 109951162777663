(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['response.hbs'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "  <div class=\"response-status\">\n    HTTP/1.1 "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.res : depth0)) != null ? stack1.status : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.$res : depth0)) != null ? stack1.statusText : stack1), depth0))
    + "\n  </div>\n  <div class=\"response-headers\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.responseHeaders : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "    <div class=\"response-header\">\n      <span class=\"response-header-name\">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + ":</span>\n      <span class=\"response-header-value\">"
    + alias2(alias1((depth0 != null ? depth0.value : depth0), depth0))
    + "</span>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "  <i>Response headers not available.</i><br/>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.res : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "<br/>\n<div id=\"response-body\" class=\"response-body\"></div>\n";
},"useData":true});
})();
