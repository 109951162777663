(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['column-collection.hbs'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "      <li class=\"row\" data-id=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" data-self=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.links : depth0)) != null ? stack1.self : stack1), depth0))
    + "\">"
    + alias2((helpers.displayName || (depth0 && depth0.displayName) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"displayName","hash":{},"data":data}))
    + "</li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"muted\"><i>None</i></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"column-header\">Collection</div>\n<ul class=\"column-body\">\n  <li>\n    <div class=\"section-header\">&#9660;Links</div>\n"
    + ((stack1 = container.invokePartial(partials["column-links.hbs"],depth0,{"name":"column-links.hbs","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </li>\n\n  <li>\n    <div class=\"section-header\">&#9660;Data</div>\n    <ul class=\"collection-data\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.data : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    </ul>\n  </li>\n</ul>\n";
},"usePartial":true,"useData":true});
})();
