(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['column-resource.hbs'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ": "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.type : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.id : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <tr>\n      <td class=\"key\">"
    + alias3((helpers.displayKey || (depth0 && depth0.displayKey) || alias2).call(alias1,(depth0 != null ? depth0.key : depth0),{"name":"displayKey","hash":{},"data":data}))
    + "</td>\n      <td class=\"value\">"
    + alias3((helpers.displayValue || (depth0 && depth0.displayValue) || alias2).call(alias1,(depth0 != null ? depth0.value : depth0),{"name":"displayValue","hash":{},"data":data}))
    + "</td>\n    </tr>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"column-header\">\n  Resource\n  "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.type : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.id : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>\n<ul class=\"column-body\">\n  <li>\n    <div class=\"section-header\">&#9660;Links</div>\n"
    + ((stack1 = container.invokePartial(partials["column-links"],depth0,{"name":"column-links","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </li>\n\n  <li>\n    <div class=\"section-header\">&#9660;Fields</div>\n    <table class=\"resource-fields\">\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.data : depth0),{"name":"eachProperty","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </table>\n  </li>\n</ul>\n\n\n";
},"usePartial":true,"useData":true});
})();
