(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['filter.hbs'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "filter-novalue";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <option value=\""
    + alias4(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.key : depth0),((stack1 = (depths[1] != null ? depths[1].cur : depths[1])) != null ? stack1.name : stack1),{"name":"ifEqual","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " SELECTED";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <button type=\"button\" class=\"btn btn-default dropdown-toggle\" data-toggle=\"dropdown\">\n          <span class=\"filter-modifier-label\">"
    + container.escapeExpression((helpers.displayModifier || (depth0 && depth0.displayModifier) || helpers.helperMissing).call(alias1,((stack1 = (depth0 != null ? depth0.cur : depth0)) != null ? stack1.modifier : stack1),{"name":"displayModifier","hash":{},"data":data}))
    + "</span>\n          <span class=\"caret\"></span>\n        </button>\n        <ul class=\"dropdown-menu text-right pull-right\" style=\"min-width: 0\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.thisFilterSchema : depth0)) != null ? stack1.modifiers : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "            <li>\n              <a href=\"#\" onclick=\"htmlapi.filterModifierChange(this); return false;\" data-value=\""
    + alias1(container.lambda(depth0, depth0))
    + "\" data-label=\""
    + alias1((helpers.displayModifier || (depth0 && depth0.displayModifier) || alias3).call(alias2,depth0,{"name":"displayModifier","hash":{},"data":data}))
    + "\">\n                "
    + alias1((helpers.displayModifier || (depth0 && depth0.displayModifier) || alias3).call(alias2,depth0,{"name":"displayModifier","hash":{},"data":data}))
    + "\n              </a>\n            </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        =\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "    <select class=\"form-control filter-value\" id=\"filter_"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.cur : depth0)) != null ? stack1.name : stack1), depth0))
    + "_value\">\n      <option value=\"\"></option>\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.options : depth0),{"name":"eachProperty","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\n";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,((stack1 = (depths[1] != null ? depths[1].cur : depths[1])) != null ? stack1.value : stack1),(depth0 != null ? depth0.value : depth0),{"name":"ifEqual","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <input type=\"text\" class=\"form-control input-sm filter-value\" id=\"filter_"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.cur : depth0)) != null ? stack1.name : stack1), depth0))
    + "_value\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.cur : depth0)) != null ? stack1.value : stack1), depth0))
    + "\" />\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.lambda, alias4=container.escapeExpression;

  return "<div class=\"filter "
    + ((stack1 = (helpers.ifInList || (depth0 && depth0.ifInList) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.cur : depth0)) != null ? stack1.modifier : stack1),"null,notnull",{"name":"ifInList","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-prefix=\"filter_"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.cur : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">\n  <div class=\"input-group filter-key\">\n    <span class=\"input-group-btn\">\n      <button type=\"button\" class=\"btn btn-primary\" onclick=\"htmlapi.filterRemove(this)\"><i class=\"glyphicon glyphicon-minus-sign\"></i></button>\n    </span>\n    <select class=\"form-control\" id=\"filter_"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.cur : depth0)) != null ? stack1.name : stack1), depth0))
    + "_name\" onchange=\"htmlapi.filterChange(this)\">\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || alias2).call(alias1,(depth0 != null ? depth0.allFilterSchema : depth0),{"name":"eachProperty","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\n    <span class=\"input-group-btn\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.thisFilterSchema : depth0)) != null ? stack1.modifiers : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "    </span>\n    <input type=\"hidden\" class=\"filter-modifier-input\" id=\"filter_"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.cur : depth0)) != null ? stack1.name : stack1), depth0))
    + "_modifier\" value=\""
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.cur : depth0)) != null ? stack1.modifier : stack1), depth0))
    + "\"/>\n  </div>\n\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.options : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true});
})();
