(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['edit.hbs'] = template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <div class=\"well\" style=\"padding: 10px; margin-bottom: 10px;\">"
    + container.escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.typeField : depth0),{"name":"with","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <tr>\n        <td>\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.required : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n          "
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + ":\n        </td>\n        <td>\n          <span class=\"inactive\">schema name</span>\n        </td>\n        <td>\n"
    + ((stack1 = container.invokePartial(partials["field.hbs"],depth0,{"name":"field.hbs","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </td>\n      </tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "<span class=\"required\">*</span>";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fields : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "      <tr>\n        <td>\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.required : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n          "
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </td>\n        <td>\n          <span class=\"inactive\">"
    + ((stack1 = ((helper = (helper = helpers.displayType || (depth0 != null ? depth0.displayType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayType","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n        </td>\n        <td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.writable : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "        </td>\n      </tr>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <span class=\"tip\" data-title=\""
    + container.escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data}) : helper)))
    + "\"><i class=\"glyphicon glyphicon-info-sign\"></i></span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["field.hbs"],depth0,{"name":"field.hbs","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <span class=\"inactive\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                "
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data}) : helper)))
    + "\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                &ndash;\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <tr>\n      <td colspan=\"3\" style=\"text-align: center;\">\n        <br/>\n"
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"action",{"name":"ifEqual","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "      </td>\n    </tr>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "          <i>This action has no inputs</i>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"update",{"name":"ifEqual","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    return "            <i>This resource has no fields that can be edited</i>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "            <i>This resource has no fields that can be set</i>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<form id=\"edit-form\">\n<div id=\"request-input\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <table class=\"fixed\" width=\"100%\">\n  <tr>\n    <th>Field Name</th>\n    <th>Type</th>\n    <th width=\"50%\">Value</th>\n  </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.typeField : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasFields : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "  </table>\n</div>\n\n<div id=\"request-output\">\n</div>\n</form>\n";
},"usePartial":true,"useData":true});
})();
