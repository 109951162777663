(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['filters.hbs'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"filter-controls\">\n    <button type=\"button\" class=\"btn btn-primary btn-sm\" onclick=\"htmlapi.filterClear();\"><span class=\"glyphicon glyphicon-ban-circle\"></span> Clear</button>\n    <button type=\"button\" class=\"btn btn-primary btn-sm\" onclick=\"htmlapi.filterAdd();\"><span class=\"glyphicon glyphicon-plus-sign\"></span> Add</button>\n    <button type=\"submit\" class=\"btn btn-primary btn-sm\"><span class=\"glyphicon glyphicon-filter\"></span> Apply</button>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"filter-body\">\n</div>\n<div id=\"no-filters\" class=\"inactive\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasFilters : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">None</div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.canFilter : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
})();
