(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['request.hbs'] = template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "-u <i>\""
    + container.escapeExpression(((helper = (helper = helpers.curl_user || (depth0 != null ? depth0.curl_user : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"curl_user","hash":{},"data":data}) : helper)))
    + "\"</i> \\<br/>";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.rawBody : depth0),{"name":"eachProperty","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.blob : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "          -F "
    + alias1(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data}) : helper)))
    + "=\"@"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.value : stack1), depth0))
    + "\" \\<br/>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "          -F "
    + alias1(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data}) : helper)))
    + "=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.value : stack1), depth0))
    + "\" \\<br/>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.contentType : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.body : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        -H 'Content-Type: "
    + container.escapeExpression(((helper = (helper = helpers.contentType || (depth0 != null ? depth0.contentType : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"contentType","hash":{},"data":data}) : helper)))
    + "' \\<br/>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        -d '"
    + container.escapeExpression(((helper = (helper = helpers.body || (depth0 != null ? depth0.body : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"body","hash":{},"data":data}) : helper)))
    + "' \\<br/>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    Content-Type: "
    + container.escapeExpression(((helper = (helper = helpers.contentType || (depth0 != null ? depth0.contentType : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"contentType","hash":{},"data":data}) : helper)))
    + "<br/>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "    Content-Length: <i>unknown</i><br/>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.contentLength : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      Content-Length: "
    + container.escapeExpression(((helper = (helper = helpers.contentLength || (depth0 != null ? depth0.contentLength : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"contentLength","hash":{},"data":data}) : helper)))
    + "<br/>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || alias2).call(alias1,(depth0 != null ? depth0.rawBody : depth0),{"name":"eachProperty","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    --"
    + container.escapeExpression(((helper = (helper = helpers.boundary || (depth0 != null ? depth0.boundary : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"boundary","hash":{},"data":data}) : helper)))
    + "--\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      --"
    + container.escapeExpression(((helper = (helper = helpers.boundary || (depth0 != null ? depth0.boundary : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"boundary","hash":{},"data":data}) : helper)))
    + "<br/>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.blob : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "        Content-Disposition: form-data; name=\""
    + alias1(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data}) : helper)))
    + "\"; filename=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.value : stack1), depth0))
    + "\"<br/><br/>\n        Content-Type: application/octet-stream<br/><br/>\n        <i>(binary data)</i><br/>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "        Content-Disposition: form-data; name=\""
    + alias1(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data}) : helper)))
    + "\"<br/><br/>\n        "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.value : stack1), depth0))
    + "<br/>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.prettyBody : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "      <div class=\"json\">"
    + ((stack1 = ((helper = (helper = helpers.prettyBody || (depth0 != null ? depth0.prettyBody : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"prettyBody","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<b>cURL command line:</b>\n<br/>\n<div id=\"curl\" class=\"well\">\n  curl \n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.curl_user : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    -X "
    + alias4(((helper = (helper = helpers.method || (depth0 != null ? depth0.method : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"method","hash":{},"data":data}) : helper)))
    + " \\<br/>\n    -H 'Accept: application/json'  \\<br/>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.rawBody : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "    '"
    + alias4(((helper = (helper = helpers.baseUrl || (depth0 != null ? depth0.baseUrl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"baseUrl","hash":{},"data":data}) : helper)))
    + alias4(((helper = (helper = helpers.path || (depth0 != null ? depth0.path : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data}) : helper)))
    + "'\n</div>\n\n<b>HTTP Request:</b><br/>\n<div id=\"request\" class=\"well\">\n  HTTP/1.1 "
    + alias4(((helper = (helper = helpers.method || (depth0 != null ? depth0.method : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"method","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.path || (depth0 != null ? depth0.path : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data}) : helper)))
    + "<br/>\n  Host: "
    + alias4(((helper = (helper = helpers.host || (depth0 != null ? depth0.host : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"host","hash":{},"data":data}) : helper)))
    + "<br/>\n  Accept: application/json<br/>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.contentType : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.rawBody : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "\n  <br/>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.rawBody : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(27, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n\n<b>HTTP Response:</b><br/>\n<div id=\"response\" class=\"well\">\n  <div id=\"notsent\" class=\"inactive\" style=\"padding: 20px; text-align: center;\">Click below to send request.</div>\n  <div id=\"waiting\" class=\"inactive\" style=\"padding: 20px; text-align: center; display: none;\">Waiting for response...</div>\n  <div id=\"result\" style=\"display: none;\"></div>\n</div>\n";
},"useData":true});
})();
