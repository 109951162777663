(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['body.hbs'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <button type=\"button\" class=\"btn btn-primary btn-sm\" onclick=\"htmlapi.create()\"><span class=\"glyphicon glyphicon-plus\"></span> Create</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <button type=\"button\" class=\"btn btn-primary btn-sm\" onclick=\"htmlapi.update()\"><span class=\"glyphicon glyphicon-edit\"></span> Edit</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <button type=\"button\" class=\"btn btn-primary btn-sm\" onclick=\"htmlapi.remove()\"><span class=\"glyphicon glyphicon-trash\"></span> Delete</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <button type=\"button\" class=\"btn btn-success btn-sm\" data-action=\""
    + alias4(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(1, data, 0),"data":data})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data}) : helper)))
    + "</button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "onclick=\"htmlapi.showAction(this);\"";
},"12":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"inactive\">None</span>\n";
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <form>\n        <div class=\"row\">\n          <div class=\"col-xs-7\">\n            <label>Sort By</label>\n            <select id=\"sortBy\" class=\"form-control\" onchange=\"htmlapi.sortChange(this)\">\n                "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.sort : stack1)) != null ? stack1.name : stack1),{"name":"unless","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || helpers.helperMissing).call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.sortLinks : stack1),{"name":"eachProperty","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n          </div>\n          <div class=\"col-xs-5\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.sort : stack1)) != null ? stack1.name : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </form>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "<option value=\"\">(Not Sorted)</option>";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                  <option value=\""
    + alias4(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data}) : helper)))
    + "\""
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,((stack1 = ((stack1 = (depths[2] != null ? depths[2].data : depths[2])) != null ? stack1.sort : stack1)) != null ? stack1.name : stack1),(depth0 != null ? depth0.key : depth0),{"name":"ifEqual","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return " SELECTED";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <label>Direction</label>\n              <div class=\"btn-group btn-group-sm\">\n"
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.sort : stack1)) != null ? stack1.order : stack1),"desc",{"name":"ifEqual","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data})) != null ? stack1 : "")
    + "              </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "                  <button type=\"button\" class=\"btn btn-default btn-sm\" onclick=\"htmlapi.sortOrderChange();\"><span class=\"glyphicon glyphicon-sort-by-attributes\"></span></button>\n                  <button type=\"button\" class=\"btn btn-default btn-sm active\"><span class=\"glyphicon glyphicon-sort-by-attributes-alt\"></span></button>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "                  <button type=\"button\" class=\"btn btn-default btn-sm active\"><span class=\"glyphicon glyphicon-sort-by-attributes\"></span></button>\n                  <button type=\"button\" class=\"btn btn-default btn-sm\" onclick=\"htmlapi.sortOrderChange();\"><span class=\"glyphicon glyphicon-sort-by-attributes-alt\"></span></button>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "      <label>Sort</label>\n      <p class=\"inactive\">Not available</p>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <div>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.pagination : stack1)) != null ? stack1.partial : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(46, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\n      <div>\n        <form class=\"form-horizontal\">\n        <div class=\"input-group input-group-sm\">\n          <span class=\"input-group-addon\">Limit:</span>\n          <input class=\"form-control\" type=\"number\" min=\"0\" step=\"100\" id=\"limit\" name=\"limit\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.pagination : stack1)) != null ? stack1.limit : stack1), depth0))
    + "\"/>\n          <span class=\"input-group-btn\">\n            <button type=\"submit\" class=\"btn btn-primary btn-sm\" onclick=\"htmlapi.setLimit($('limit').val())\"><span class=\"glyphicon glyphicon-signal\"></span> Set</button>\n          </span>\n        </div>\n        </form>\n      </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.pagination : stack1)) != null ? stack1.first : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(30, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.pagination : stack1)) != null ? stack1.prev : stack1),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.pagination : stack1)) != null ? stack1.next : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.pagination : stack1)) != null ? stack1.last : stack1),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.links : stack1)) != null ? stack1.self : stack1),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.pagination : stack1)) != null ? stack1.first : stack1),{"name":"ifEqual","hash":{},"fn":container.program(30, data, 0),"inverse":container.program(32, data, 0),"data":data})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    return "              <button type=\"button\" class=\"btn btn-success btn-sm\" disabled=\"disabled\"><span class=\"glyphicon glyphicon-step-backward\"></span></button>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <a class=\"btn btn-success btn-sm\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.pagination : stack1)) != null ? stack1.first : stack1), depth0))
    + "\"><span class=\"glyphicon glyphicon-step-backward\"></span></a>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <a class=\"btn btn-success btn-sm\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.pagination : stack1)) != null ? stack1.prev : stack1), depth0))
    + "\"><span class=\"glyphicon glyphicon-chevron-left\"></span></a>\n";
},"36":function(container,depth0,helpers,partials,data) {
    return "              <button type=\"button\" class=\"btn btn-success btn-sm\" disabled=\"disabled\"><span class=\"glyphicon glyphicon-chevron-left\"></span></button>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <a class=\"btn btn-success btn-sm\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.pagination : stack1)) != null ? stack1.next : stack1), depth0))
    + "\"><span class=\"glyphicon glyphicon-chevron-right\"></span></a>\n";
},"40":function(container,depth0,helpers,partials,data) {
    return "              <button type=\"button\" class=\"btn btn-success btn-sm\" disabled=\"disabled\"><span class=\"glyphicon glyphicon-chevron-right\"></span></button>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <a class=\"btn btn-success btn-sm\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.pagination : stack1)) != null ? stack1.last : stack1), depth0))
    + "\"><span class=\"glyphicon glyphicon-step-forward\"></span></a>\n";
},"44":function(container,depth0,helpers,partials,data) {
    return "              <button type=\"button\" class=\"btn btn-success btn-sm\" disabled=\"disabled\"><span class=\"glyphicon glyphicon-step-forward\"></span></button>\n";
},"46":function(container,depth0,helpers,partials,data) {
    return "          <p class=\"inactive\">Full result</p>\n";
},"48":function(container,depth0,helpers,partials,data) {
    return "      <p class=\"inactive\">Not available</p>\n";
},"50":function(container,depth0,helpers,partials,data) {
    return "      <a href=\"#\" onclick=\"Cookie.set('apiview','explorer'); explorer.show(); return false\">Explorer View</a><br/>\n";
},"52":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      <a href=\""
    + container.escapeExpression(((helper = (helper = helpers.docsPage || (depth0 != null ? depth0.docsPage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"docsPage","hash":{},"data":data}) : helper)))
    + "\" target=\"apidocs\">Documentation</a><br/>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      <span class=\"user\">"
    + container.escapeExpression(((helper = (helper = helpers.user || (depth0 != null ? depth0.user : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"56":function(container,depth0,helpers,partials,data) {
    return "      <a href=\"#\" onclick=\"htmlapi.logout(); return false\">Log Out</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div id=\"header\">\n  <div id=\"header-error\" style=\"display: none;\"><b>Error:</b> "
    + container.escapeExpression(((helper = (helper = helpers.error || (depth0 != null ? depth0.error : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"error","hash":{},"data":data}) : helper)))
    + "</div>\n  <div iv=\"header-body\">\n    <label>Operations</label>\n    <div id=\"operations\">\n        <button type=\"button\" class=\"btn btn-primary btn-sm\" onclick=\"htmlapi.up()\" "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.operations : depth0)) != null ? stack1.up : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><span class=\"glyphicon glyphicon-arrow-up\"></span> Up</button>\n        <button type=\"button\" class=\"btn btn-primary btn-sm\" onclick=\"htmlapi.reload()\"><span class=\"glyphicon glyphicon-refresh\"></span> Reload</button>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.operations : depth0)) != null ? stack1.post : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.operations : depth0)) != null ? stack1.put : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.operations : depth0)) != null ? stack1["delete"] : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n    <br/>\n\n    <label>Actions</label>\n    <div>\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || alias2).call(alias1,(depth0 != null ? depth0.actions : depth0),{"name":"eachProperty","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "    </div>\n    <br/>\n\n    <label>Filter</label>\n    <form onsubmit=\"htmlapi.filterApply(); return false;\">\n      <div id=\"filters\"></div>\n    </form>\n    <br/>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.sortLinks : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.program(25, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "    <br/>\n\n    <label>Pagination</label>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.pagination : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.program(48, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "    <br/>\n  </div>\n\n  <div class=\"footer\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.explorer : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.docsPage : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.user : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.logout : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div>\n\n<div id=\"json\" class=\"json\"></div>\n";
},"useData":true,"useDepths":true});
})();
