(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['explorer.hbs'] = template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <span class=\"user\">"
    + container.escapeExpression(((helper = (helper = helpers.user || (depth0 != null ? depth0.user : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <a href=\""
    + container.escapeExpression(((helper = (helper = helpers.docs || (depth0 != null ? depth0.docs : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"docs","hash":{},"data":data}) : helper)))
    + "\" target=\"cloudapidocs\">Documentation</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"header\">\n  <table id=\"header-body\" width=\"100%\">\n  <tr>\n  <!--\n    <td>\n      <form>\n        <label class=\"checkbox\"><input type=\"checkbox\" id=\"show_removed\"/>Show removed resources</label><br/>\n      </form>\n    </td>\n  -->\n    <td style=\"vertical-align: top; text-align: right\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.user : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <a href=\"#\" onclick=\"htmlapi.logout(); return false\">Log Out</a><br/>\n\n      <a href=\"#\" onclick=\"Cookie.set('apiview','browse'); htmlapi.show(); return false\">JSON View</a><br/>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.docs : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </td>\n  </tr>\n  </table>\n</div>\n\n<div id=\"explorer\">\n  <div id=\"explorer-wrapper\">\n  </div>\n</div>\n";
},"useData":true});
})();
